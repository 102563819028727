<template>
  <div class="shipping-price-container layout-padding">
    <PageTitle
      class="my-8"
      :title="content.termsAndConditions"
      :childOne="content.termsAndConditions"
      routeOne="/termsAndConditions"
      type="withBg"
    />
    <div class="my-16">
      <div class="terms mt-8" v-for="term in terms" :key="term.id">
        <div class="title lora-bold">{{ term.title }}</div>
        <div class="description mt-4">
          {{ term.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  mounted() {
    this.$store.dispatch("extra/terms");
  },
  computed: {
    terms() {
      return this.$store.getters["extra/terms"];
    },
  },
};
</script>